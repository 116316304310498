import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  IconButton,
  InputAdornment,
  Backdrop, CircularProgress,Alert
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // アイコン用のインポート
import GoogleAuthButton from "./GoogleAuthButton";

interface LoginFormProps {
  onLogin: (email: string, password: string) => Promise<boolean>;
  onGoogleLogin: (token: string) => Promise<void>; // 非同期関数に変更
}

const Login: React.FC<LoginFormProps> = ({ onLogin, onGoogleLogin }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // パスワード表示切り替え
  const [errorMessage, setErrorMessage] = useState<string | string[] | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false); // ログイン処理中の状態

    // 汎用エラーハンドリング関数
    const handleError = (error: any) => {
      if (error?.response?.status === 401) {
        setErrorMessage("メールアドレスまたはパスワードが間違っています");
      } else if (error?.response?.status === 400) {
        setErrorMessage("入力内容を確認してください");
      } else if (error?.message === "Network Error") {
        setErrorMessage("ネットワークエラーが発生しました。インターネット接続を確認してください。");
      } else {
        setErrorMessage("予期しないエラーが発生しました。時間をおいて再試行してください。");
      }
    };

// メールアドレスのバリデーション関数
const isValidEmail = (email: string) => {
  // メールアドレスの基本構文を検証
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

  // 使用禁止の文字（絵文字や特殊文字）
  const forbiddenCharsRegex = /[^\x20-\x7E]/; // ASCII範囲外の文字を検出

  // 絵文字や特殊文字が含まれているか確認
  if (forbiddenCharsRegex.test(email)) {
    return false; // 絵文字や特殊文字が検出された場合は無効
  }

  // メールアドレスのフォーマット検証
  return emailRegex.test(email);
};


  // メールアドレスとパスワードによるログイン処理
  const handleLogin = async () => {
    if (!email || !password) {
      setErrorMessage("メールアドレスとパスワードを入力してください");
      return;
    }
    
    if (email && password) {
      setIsLoggingIn(true); // ログイン処理開始
      setErrorMessage(null); // エラーメッセージをリセット
      try {
        const isSuccess = await onLogin(email, password); // booleanを受け取る
        if (isSuccess) {
          navigate("/"); // ログイン成功時にのみ遷移
        } else {
          handleError({ response: { status: 401 } }); // ログイン失敗を401エラーとして扱う
        }
      } catch (error) {
        console.error("Login failed:", error);
        handleError(error); // エラーハンドリング関数を呼び出し
      }
      finally {
        setIsLoggingIn(false); // ログイン処理終了
      }
    } else {
      setErrorMessage(["メールアドレスとパスワードを入力してください"]);
    }
  };

  const handlePasswordReset = () => {
    navigate("/password-reset");
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 4 }}>
      {/* ログイン中のインジケーター */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoggingIn}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    <Box
    component="form"
    autoComplete="on" // フォーム全体の自動補完を有効化
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h4" gutterBottom>
        ログイン
      </Typography>
      {/* エラーメッセージ表示 */}
      {errorMessage && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setErrorMessage(null)}>
            {errorMessage}
          </Alert>
        )}
      <TextField
        label="メールアドレス"
        variant="outlined"
        margin="normal"
        type="email"
        autoComplete="username" // メールアドレス用
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={{
          width: "100%", // 幅を100%に設定
          maxWidth: 450,
          margin: "0 auto", // 左右中央寄せ
        }}
      />
      <TextField
        label="パスワード"
        variant="outlined"
        margin="normal"
        type={showPassword ? "text" : "password"} // パスワード表示切り替え
        autoComplete="current-password" // パスワード用
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{
          width: "100%", // 幅を100%に設定
          maxWidth: 450,
          margin: "5 auto 0", // 左右中央寄せ
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogin}
        disabled={!isValidEmail(email) || password.length === 0} // バリデーションをチェック
        sx={{ marginTop: 2 }}
      >
        ログイン
      </Button>
      <Typography
        variant="body2"
        color="primary"
        align="center"
        style={{ cursor: "pointer", marginTop: "16px" }}
        onClick={handlePasswordReset}
      >
        パスワードを忘れた方はこちら
      </Typography>
      <Typography variant="h6" sx={{ marginTop: 3, marginBottom: 3 }}>
        または
      </Typography>
      <GoogleAuthButton onLoginSuccess={onGoogleLogin} isRegister={false} />
      <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      sx={{
        mt: 1,
        fontSize: { xs: "0.8rem", sm: "1rem" },
        px: { xs: 2, sm: 0 },
      }}
    >
      ※ Googleログインは、Googleで登録したアカウントのみご利用いただけます。
    </Typography>
    </Box>
    </Container>
  );
};

export default Login;
