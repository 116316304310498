import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Box, Button, Backdrop, CircularProgress, Alert } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";

interface GoogleAuthButtonProps {
  onLoginSuccess: (token: string) => Promise<void>; // 非同期関数として定義
  isRegister?: boolean; // 新規登録かどうかを判定するためのプロパティ
}

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  onLoginSuccess,
  isRegister = false,
}) => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false); // ログイン処理中を管理
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // エラーメッセージを管理

  const handleError = (error: any) => {
    if (error?.response?.status === 401) {
      setErrorMessage("Google認証に失敗しました。再試行してください。");
    } else if (error?.response?.status === 400) {
      setErrorMessage("リクエストに問題があります。詳細を確認してください。");
    } else if (error?.message === "Network Error") {
      setErrorMessage("ネットワークエラーが発生しました。インターネット接続を確認してください。");
    } else {
      setErrorMessage("予期しないエラーが発生しました。時間をおいて再試行してください。");
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setIsProcessing(true); // ログイン処理中
      setErrorMessage(null); // エラーメッセージをリセット
      try {
        await onLoginSuccess(response.access_token); // onLoginSuccess完了後に遷移
        navigate("/"); // ログイン・登録成功時に遷移
      } catch (error) {
        console.error("Google login handling failed", error);
        handleError(error); // エラー処理
      } finally {
        setIsProcessing(false); // 処理終了
      }
    },
    onError: (error) => {
      console.error("Google login failed");
      setIsProcessing(false); // エラー時も処理終了
      handleError(error); // エラー処理
    },
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >

      {/* エラーメッセージ表示 */}
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setErrorMessage(null)}>
          {errorMessage}
        </Alert>
      )}


      {/* ログイン処理中のインジケーター */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isProcessing} // ログイン処理中のみ表示
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Googleログインボタン */}
      <Button
        variant="outlined"
        startIcon={<GoogleIcon />}
        onClick={() => {
          setIsProcessing(true); // ボタン押下時にインジケーター表示
          googleLogin();
        }}
        sx={{ marginBottom: 2, width: "100%", maxWidth: 300 }}
        disabled={isProcessing} // 処理中はボタンを無効化
      >
        {isRegister
          ? "Googleアカウントで新規登録"
          : "Googleアカウントでログイン"}
      </Button>
    </Box>
  );
};

export default GoogleAuthButton;
