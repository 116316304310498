import React, { useState, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ColGroupDef, IHeaderParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./CutTable.css";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Collapse,
  Backdrop,
  CircularProgress,
  Slider,
  IconButton,
  AppBar,
  Toolbar,
  useMediaQuery,
  Fab,
} from "@mui/material";
import {
  Add,
  Save,
  PlayCircle,
  Camera,
  GridOn,
  Palette,
  ExpandLess,
  ExpandMore,
  Close,
  Clear,
} from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import { apiRequestWithAuth } from "../utils/auth";
import NotoSansJP from "../assets/fonts/NotoSansJP-Regular.ttf";
import { maxWidth, useTheme } from "@mui/system";

interface CustomHeaderProps extends IHeaderParams {
  updateHeaderName: (field: string, newHeaderName: string) => void;
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
  displayName,
  column,
  api,
  updateHeaderName,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [headerName, setHeaderName] = useState(displayName);

  const handleDoubleClick = () => {
    setIsEditing(true);
    column.getColDef().suppressMovable = true; // ドラッグを無効化
  };

  const handleBlur = () => {
    setIsEditing(false);
    column.getColDef().headerName = headerName; // ヘッダー名を更新
    column.getColDef().suppressMovable = false; // ドラッグを再度有効化
    api.refreshHeader(); // ヘッダーを再描画

    // headerNamesを更新
    updateHeaderName(column.getColId(), headerName);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  return isEditing ? (
    <input
      type="text"
      value={headerName}
      onChange={(e) => setHeaderName(e.target.value)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      autoFocus
      style={{ width: "100%", padding: "4px" }}
    />
  ) : (
    <div onDoubleClick={handleDoubleClick} style={{ cursor: "pointer" }}>
      {headerName}
    </div>
  );
};

interface Cut {
  id: number | null;
  img: string | null;
  timestamp: number | null;
  text: string | null;
  [key: string]: any;
}

const CutTable: React.FC<{ videoUrl: string; isPaidUser: boolean }> = ({
  videoUrl,
  isPaidUser,
}) => {
  const { id } = useParams<{ id: string }>();
  const gridRef = useRef<AgGridReact>(null);
  const [cuts, setCuts] = useState<Cut[]>([]);
  const BASE_URL = (process.env.REACT_APP_API_BASE_URL || "").trim();

  // カスタムヘッダーコンポーネント
  const CustomHeader = ({
    displayName,
    column,
    api,
    updateHeaderName,
  }: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const [headerName, setHeaderName] = useState(displayName);

    const handleDoubleClick = () => {
      setIsEditing(true);
      api.getColumnDef(column.colId).suppressMovable = true; // ドラッグを無効化
    };

    const handleBlur = () => {
      setIsEditing(false);
      api.getColumnDef(column.colId).headerName = headerName; // ヘッダー名を更新
      api.getColumnDef(column.colId).suppressMovable = false; // ドラッグを再度有効化
      api.refreshHeader(); // ヘッダーを再描画

      // headerNamesを更新
      updateHeaderName(column.colId, headerName);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        handleBlur();
      }
    };

    return isEditing ? (
      <input
        type="text"
        value={headerName}
        onChange={(e) => setHeaderName(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        autoFocus
        style={{ width: "100%", padding: "4px" }}
      />
    ) : (
      <div onDoubleClick={handleDoubleClick} style={{ cursor: "pointer" }}>
        {headerName}
      </div>
    );
  };

  const updateHeaderName = (field: string, newHeaderName: string) => {
    setHeaderNames((prev) => ({
      ...prev,
      [field]: newHeaderName,
    }));
  };

  const handleSetHeaderName = (colId: string, newHeaderName: string) => {
    setColumnDefs((prevDefs) =>
      prevDefs.map((def) =>
        def.field === colId ? { ...def, headerName: newHeaderName } : def
      )
    );
  };

  const [headerNames, setHeaderNames] = useState({
    id: "ID",
    img: "Cut",
    timestamp: "Timestamp",
    text: "Text",
  });
  const [columnDefs, setColumnDefs] = useState<any[]>([
    {
      headerName: headerNames.id,
      field: "id",
      editable: false,
      flex: 1,
      maxWidth: 50,
      headerComponent: "customHeader",
    },
    {
      headerName: headerNames.img,
      field: "img",
      flex: 2,
      minWidth: 150,
      headerComponent: "customHeader",
      editable: false,

      cellRenderer: (params: any) =>
        params.value ? (
          <img
            src={params.value}
            alt="cut"
            style={{ width: "100%", cursor: "pointer", margin: "0 auto" }}
          />
        ) : (
          "No Image"
        ),
    },
    {
      headerName: headerNames.timestamp,
      field: "timestamp",
      flex: 2,
      minWidth: 100,
      headerComponent: "customHeader",

      valueGetter: (params: any) => {
        // 常に値を返すようにする
        const timestamp = params.data?.timestamp;
        return timestamp !== null && timestamp !== undefined ? timestamp : 0;
      },
      valueFormatter: (params: any) => {
        // 常にフォーマットを適用
        const timestamp = params.value || 0;
        return new Date(timestamp * 1000).toISOString().substr(11, 12);
      },
      cellRenderer: (params: any) => {
        const timestamp = params.value || 0; // デフォルト値を設定
        const formattedTime = new Date(timestamp * 1000)
          .toISOString()
          .substr(11, 12);

        return (
          <Button
            className="timestamp-color"
            //style={{ color: tableColor === "black" ? "white" : "black" }}
            startIcon={<PlayCircle />}
            onClick={() => handleTimestampClick(timestamp)}
          >
            {formattedTime}
          </Button>
        );
      },
    },
    {
      headerName: headerNames.text,
      field: "text",
      editable: true,
      flex: 2,
      minWidth: 150,
      headerComponent: "customHeader",
      cellEditor: "agLargeTextCellEditor",
      cellEditorParams: {
        maxLength: 500,
        rows: 5,
        cols: 3,
      },
      cellEditorPopup: false, // ポップアップを無効化
      onCellKeyDown: (event: any) => {
        if (event.event.shiftKey && event.event.key === "Enter") {
          event.event.stopPropagation();
        }
      },
    },
  ]);
  const [imageQueue, setImageQueue] = useState<
    { imgUrl: string; timestamp: number }[]
  >([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [activeImage, setActiveImage] = useState<string | null>(null);
  // useDroppable 設定
  const [draggedRowIndex, setDraggedRowIndex] = useState<number | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [tableColor, setTableColor] = useState("default");
  const [videoVisible, setVideoVisible] = useState(true); // 動画の開閉状態
  const [columnSetCount, setColumnSetCount] = useState(1); // 現在の列セット数
  const [originalCuts, setOriginalCuts] = useState<Cut[]>([]); // 元データを保持
  const [rowHeights, setRowHeights] = useState<{ [key: string]: number }>({});
  const [columnWidths, setColumnWidths] = useState<{ [key: string]: number }>(
    {}
  );
  const [baseImageHeight, setBaseImageHeight] = useState<number>(150); // 初期値を150pxに設定
  const [isProcessing, setIsProcessing] = useState(false); // 保存処理中の状態
  const [activePanel, setActivePanel] = useState<string | null>(null); // ユーティリティバーのタスク状態を管理
  const [isHeightInitialized, setIsHeightInitialized] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // 初期ロードかどうか
  const activePanelRef = useRef(activePanel);
  const [permissions, setPermissions] = useState({
    allows_png_download: false,
    allows_pdf_download: false,
    allows_excel_download: false,
    allows_svg_download: false,
    allows_figma_download: false,
    allows_row_download: false,
  });
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchCutsWithEditingState = async () => {
      try {
        const url = `${BASE_URL}/api/v1/videos/${id}/cuts/`;
        const response = await apiRequestWithAuth(
          url,
          { method: "GET" },
          () => {}
        );

        if (response) {
          const editingState = response[0]?.editing_state;

          // 編集状態が保存されている場合かつロードが初回のとき
          if (editingState) {
            const initialEditingCuts = updateIds(editingState.cuts || []);
            setOriginalCuts(initialEditingCuts);
            setCuts(initialEditingCuts); // 編集状態のカット情報を優先
            setImageQueue(editingState.imageQueue || []); // 復元時に imageQueue を設定

            // レイアウトの復元
            if (editingState.layout) {
              setColumnSetCount(editingState.layout.columnSetCount || 1);
              setTableColor(editingState.layout.tableColor || "default");
              if (editingState.layout.baseImageHeight) {
                setBaseImageHeight(editingState.layout.baseImageHeight);
                setIsHeightInitialized(true);
              }
            }

            // headerNamesの状態を更新
            const newHeaders = editingState.headerNames || headerNames;
            setHeaderNames(newHeaders);
            setColumnDefs(generateColumnDefs(newHeaders)); // カラム定義を再生成

            //編集状態の保存がなく、ロードが初回のとき
          } else if (!editingState) {
            // 編集状態がない場合の初期ロード
            const fieldMapping = {
              id: "id",
              img: "img_url",
              timestamp: "timestamp",
              text: "text",
            };

            // レスポンスをフィールドマッピング
            const transformedCuts = response.map((cut: any) => {
              const mappedCut: { [key: string]: any } = {};
              for (const [clientField, serverField] of Object.entries(
                fieldMapping
              )) {
                mappedCut[clientField] = cut[serverField];
              }
              return mappedCut;
            });

            // 初期レスポンスを基にカット情報をセット
            const initialCuts = updateIds(transformedCuts);
            setCuts(initialCuts);
            setOriginalCuts(initialCuts);
            setImageQueue([]);
            setColumnDefs(generateColumnDefs(headerNames)); // 初期カラム定義を設定
          }
        }
      } catch (error) {
        console.error("Error fetching cuts and editing state:", error);
      }
    };
    const fetchUserPermissions = async () => {
      try {
        const url = `${BASE_URL}/api/v1/user-info/`;
        const response = await apiRequestWithAuth(
          url,
          { method: "GET" },
          () => {}
        );
        if (response && response.permissions) {
          setPermissions(response.permissions);
        }
      } catch (error) {
        console.error("Failed to fetch user permissions:", error);
      }
    };

    // 初回ロードもしくは編集が行われていない場合のみデータ取得
    if (isInitialLoad) {
      fetchCutsWithEditingState();
      setIsInitialLoad(false); // 初回ロードフラグを解除isInitialLoadisInitialLoad
      fetchUserPermissions();
    } else {
      const updatedCuts = [...cuts]; // 現在のカットデータ
      const updatedColumnDefs = generateColumnDefs(headerNames); // ヘッダー名に基づく再生成

      setCuts(updateIds(updatedCuts)); // IDをリセット
      setColumnDefs(updatedColumnDefs); // カラム定義の更新

      // 現在の高さを取得して設定
      if (baseImageHeight && !isHeightInitialized) {
        //console.log("現在の画像高さを使用:", baseImageHeight);
        getRowHeight(baseImageHeight);
        setBaseImageHeight(baseImageHeight); // 現状の高さを再設定
        setIsHeightInitialized(true); // 初期化済みフラグをセット
      }
      if (gridRef.current?.api) {
        // グリッドの行高さをリセット
        gridRef.current.api.resetRowHeights();
      }
    }

    // グリッドの行高さをリセット
    gridRef.current?.api?.resetRowHeights();
  }, [baseImageHeight, isHeightInitialized, isInitialLoad]);

  const generateColumnDefs = (headers: typeof headerNames) => [
    {
      headerName: headers.id,
      field: "id",
      editable: false,
      flex: 1,
      minWidth: 50,
      maxWidth: 100,
      headerComponent: "customHeader",
    },
    {
      headerName: headers.img,
      field: "img",
      flex: 2,
      minWidth: 300,
      headerComponent: "customHeader",
      cellRenderer: imageCellRenderer,
      editable: false,
    },
    {
      headerName: headers.timestamp,
      field: "timestamp",
      flex: 2,
      minWidth: 100,
      maxWidth: 200,
      headerComponent: "customHeader",
      valueGetter: (params: any) => {
        const timestamp = params.data?.timestamp;
        return timestamp !== null && timestamp !== undefined ? timestamp : 0;
      },
      valueFormatter: (params: any) => {
        const timestamp = params.value || 0;
        return new Date(timestamp * 1000).toISOString().substr(11, 12);
      },
      cellRenderer: (params: any) => {
        const timestamp = params.value || 0;
        const formattedTime = new Date(timestamp * 1000)
          .toISOString()
          .substr(11, 12);

        return (
          <Button
            className="timestamp-color"
            //style={{ color: tableColor === "black" ? "white" : "black" }}
            startIcon={<PlayCircle />}
            onClick={() => handleTimestampClick(timestamp)}
          >
            {formattedTime}
          </Button>
        );
      },
    },
    {
      headerName: headerNames.text,
      field: "text",
      editable: true,
      flex: 2,
      minWidth: 150,
      headerComponent: "customHeader",
      cellEditor: "agLargeTextCellEditor",
      cellEditorParams: {
        maxLength: 500,
        rows: 5,
        cols: 30,
      },
      cellEditorPopup: false, // ポップアップを無効化
      onCellKeyDown: (event: any) => {
        if (event.event.shiftKey && event.event.key === "Enter") {
          event.event.stopPropagation();
        }
      },
    },
  ];

  const createColumnDef = (index: number) => [
    {
      headerName: headerNames.id,
      field: `id_${index}`,
      editable: false,
      flex: 1,
      minWidth: 50,
      maxWidth: 100,
      headerComponent: "customHeader",
    },
    {
      headerName: headerNames.img,
      field: `img_${index}`,
      flex: 2,
      minWidth: 150,
      headerComponent: "customHeader",
      cellRenderer: (params: any) =>
        params.value ? (
          <img
            src={params.value}
            alt={`cut_${index}`}
            style={{ width: "100px", cursor: "pointer" }}
          />
        ) : (
          "No Image"
        ),
    },
    {
      headerName: headerNames.timestamp,
      field: `timestamp_${index}`,
      flex: 2,
      minWidth: 100,
      maxWidth: 200,
      headerComponent: "customHeader",
      valueGetter: (params: any) =>
        params.data?.[`timestamp_${index}`] !== undefined
          ? params.data[`timestamp_${index}`]
          : null,
      valueFormatter: (params: any) => {
        const timestamp = params.value ?? null;
        if (timestamp === null) return "N/A"; // 値がない場合
        return new Date(timestamp * 1000).toISOString().substr(11, 12); // フォーマット済み表示
      },
      cellRenderer: (params: any) => {
        const timestamp = params.value ?? 0; // 値がない場合デフォルトは0
        const formattedTime = new Date(timestamp * 1000)
          .toISOString()
          .substr(11, 12);
        return (
          <Button
            className="timestamp-color"
            //style={{ color: tableColor === "black" ? "white" : "black" }}
            startIcon={<PlayCircle />}
            onClick={() => handleTimestampClick(timestamp)}
          >
            {formattedTime}
          </Button>
        );
      },
    },
    {
      headerName: headerNames.text,
      field: "text",
      editable: true,
      flex: 2,
      minWidth: 150,
      headerComponent: "customHeader",
      cellEditor: "agLargeTextCellEditor",
      cellEditorParams: {
        maxLength: 500,
        rows: 5,
        cols: 30,
      },
      cellEditorPopup: false, // ポップアップを無効化
      onCellKeyDown: (event: any) => {
        if (event.event.shiftKey && event.event.key === "Enter") {
          event.event.stopPropagation();
        }
      },
    },
  ];

  const updateIds = (rows: Cut[]): Cut[] => {
    // 行データに連番の ID を割り当てる
    return rows.map((row, index) => ({
      ...row,
      id: index, // 0 からの連番を割り当てる
    }));
  };

  // 画像がロードされた時に高さを記録
  const handleImageLoad = (id: number, height: number) => {
    if (baseImageHeight === null) {
      setBaseImageHeight(height); // 最初にロードされた画像の高さを基準にする
    }
    setRowHeights((prev) => {
      const updated = { ...prev, [id]: height };
      gridRef.current?.api.resetRowHeights(); // 行の高さをリセット
      return updated;
    });

    setRowHeights((prev) => {
      const updated = { ...prev, [id]: height };
      if (gridRef.current?.api.resetRowHeights()) {
        gridRef.current?.api.resetRowHeights(); // 行の高さをリセット
      }
      return updated;
    });
  };

  const adjustImageHeight = (newHeight: number) => {
    setIsHeightInitialized(true);
    setBaseImageHeight(newHeight);
    setIsEditing(true);

    if (gridRef.current?.api) {
      gridRef.current.api.resetRowHeights(); // 行の高さをリセット
      gridRef.current.api.redrawRows(); // 行全体を再描画
    }
  };

  const getRowHeight = (params: any) => {
    return baseImageHeight || 150; // 基準高さを使用
  };

  const imageCellRenderer = (params: any) => {
    if (!params.value) {
      return "No Image";
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: `${baseImageHeight}px`, // 高さを動的に設定
        }}
      >
        <img
          src={params.value}
          alt="cut"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain", // アスペクト比を維持
          }}
        />
      </div>
    );
  };

  const formatTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);
    return date.toISOString().substr(11, 12); // hh:mm:ss.sss形式
  };

  const handleAddRow = () => {
    const newRow: Cut = {
      id: cuts.length,
      img: "", // 初期状態では画像なし
      timestamp: videoRef.current?.currentTime || 0,
      text: "",
    };

    setCuts((prevCuts) => {
      const updatedCuts = [...prevCuts, newRow];
      const height = baseImageHeight || 100; // 基準高さを適用
      handleImageLoad(cuts.length, height);
      setIsEditing(true); // ユーザーが編集したことを追跡
      return updatedCuts;
    });
  };

  const handleAddRowAt = (position: "above" | "below") => {
    if (!gridRef.current) return;

    const selectedNode = gridRef.current.api.getSelectedNodes()[0];
    const newRow: Cut = { id: null, img: "", timestamp: null, text: "" };

    setCuts((prevCuts) => {
      let newCuts = [...prevCuts];
      if (selectedNode && typeof selectedNode.rowIndex === "number") {
        const selectedIndex = selectedNode.rowIndex;
        const insertIndex =
          position === "above" ? selectedIndex : selectedIndex + 1;
        newCuts.splice(insertIndex, 0, newRow);
        handleImageLoad(insertIndex, 100); // 新しい行の高さを設定（デフォルト値）
      } else {
        newCuts.push(newRow);
        handleImageLoad(prevCuts.length, 100); // 新しい行の高さを設定（デフォルト値）
      }
      setIsEditing(true); // ユーザーが編集したことを追跡
      return updateIds(newCuts);
    });
  };

  const handleDeleteRow = () => {
    if (!gridRef.current) return;
    const selectedNodes = gridRef.current.api.getSelectedNodes(); // 選択された行
    if (selectedNodes.length === 0) return;

    const selectedIndexes = selectedNodes.map((node) => node.rowIndex);
    let newCuts = cuts.filter((_, index) => !selectedIndexes.includes(index)); // 選択行を削除

    // ID を再計算して更新
    setCuts(updateIds(newCuts));
    setIsEditing(true); // ユーザーが編集したことを追跡
  };

  const handleCutScene = () => {
    if (videoRef.current) {
      // Cross-Origin 設定を追加
      videoRef.current.crossOrigin = "anonymous";

      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        try {
          // 現在の動画の時刻を取得
          const currentTimestamp = Math.floor(videoRef.current.currentTime);
          const formattedTimestamp = formatTimestamp(currentTimestamp);

          // 既存のエントリを検索
          const existingIndex = imageQueue.findIndex(
            (item) => item.timestamp === currentTimestamp
          );

          // 画像を生成
          ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
          const imgUrl = canvas.toDataURL("image/png");

          if (existingIndex !== -1) {
            // 同じタイムスタンプのエントリがある場合、画像を更新
            const updatedQueue = [...imageQueue];
            updatedQueue[existingIndex].imgUrl = imgUrl;
            setImageQueue(updatedQueue);
          } else {
            // 新しいエントリを追加
            setImageQueue([
              ...imageQueue,
              { imgUrl, timestamp: currentTimestamp },
            ]);
          }
          setIsEditing(true); // ユーザーが編集したことを追跡
        } catch (error) {
          console.error("Error capturing image:", error);
          alert("画像を切り出せませんでした。CORS設定を確認してください。");
        }
      }
    }
  };

  const addImageToTable = (imgUrl: string, timestamp: number) => {
    const newRow: Cut = {
      id: cuts.length + 1,
      img: imgUrl,
      timestamp,
      text: "",
    };

    const updatedCuts = [...cuts, newRow].sort((a, b) => {
      const aTimestamp = a.timestamp ?? Number.MAX_SAFE_INTEGER;
      const bTimestamp = b.timestamp ?? Number.MAX_SAFE_INTEGER;
      return aTimestamp - bTimestamp;
    });

    const cutsWithUpdatedIds = updateIds(updatedCuts);
    setCuts(cutsWithUpdatedIds);

    const height = baseImageHeight || 100; // 基準高さを適用
    handleImageLoad(cutsWithUpdatedIds.length - 1, height);

    setImageQueue((prevQueue) =>
      prevQueue.filter((img) => img.imgUrl !== imgUrl)
    );
    setSelectedImage(null);
    setIsEditing(true); // ユーザーが編集したことを追跡
  };

  const handleTimestampClick = (timestamp: number) => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = timestamp; // タイムスタンプを設定
        videoRef.current.play(); // 動画を再生
      }
    };

    // activePanelがデフォルトまたは他のパネルの場合
    if (!activePanelRef.current || activePanelRef.current !== "imageCutting") {
      setActivePanel("imageCutting");
      setTimeout(playVideo, 100); // パネル切り替え後に動画を再生
    } else {
      playVideo(); // すでに "imageCutting" の場合は即時再生
    }
  };


  const rowClassRules = {
    "row-black": () => tableColor === "black",
    "row-gray": () => tableColor === "gray",
    "row-striped": (params: any) =>
      tableColor === "striped" && params.node.rowIndex % 2 === 0,
    "row-default": () => tableColor === "default",
  };
  const handleTableColorChange = (event: SelectChangeEvent<string>) => {
    setTableColor(event.target.value as string);
    setIsEditing(true); // ユーザーが編集したことを追跡
  };

  const handleAddFromQueue = (img: { imgUrl: string; timestamp: number }) => {
    setCuts([
      ...cuts,
      { id: cuts.length, img: img.imgUrl, timestamp: img.timestamp, text: "" },
    ]);
    setImageQueue(imageQueue.filter((item) => item.imgUrl !== img.imgUrl));
  };

  const splitIntoColumns = () => {
    const nextColumnSetCount = columnSetCount + 1; // 次の列セット数
    const chunkSize = Math.ceil(originalCuts.length / nextColumnSetCount);

    const updatedCuts: Partial<Cut>[] = Array(chunkSize)
      .fill(null)
      .map(() => ({}));

    for (let columnIndex = 0; columnIndex < nextColumnSetCount; columnIndex++) {
      for (let rowIndex = 0; rowIndex < chunkSize; rowIndex++) {
        const index = rowIndex + chunkSize * columnIndex;
        if (index < originalCuts.length) {
          const originalCut = originalCuts[index];
          updatedCuts[rowIndex][`id_${columnIndex + 1}`] = originalCut.id; // そのままコピー
          updatedCuts[rowIndex][`img_${columnIndex + 1}`] = originalCut.img;
          updatedCuts[rowIndex][`timestamp_${columnIndex + 1}`] =
            originalCut.timestamp !== undefined ? originalCut.timestamp : null; // そのまま保持
          updatedCuts[rowIndex][`text_${columnIndex + 1}`] = originalCut.text;
        } else {
          updatedCuts[rowIndex][`id_${columnIndex + 1}`] = null;
          updatedCuts[rowIndex][`img_${columnIndex + 1}`] = null;
          updatedCuts[rowIndex][`timestamp_${columnIndex + 1}`] = null; // 空値
          updatedCuts[rowIndex][`text_${columnIndex + 1}`] = null;
        }
      }
    }

    const newColumnDefs: any[] = [];
    for (let i = 1; i <= nextColumnSetCount; i++) {
      newColumnDefs.push(...createColumnDef(i));
    }

    setCuts(updatedCuts as Cut[]);
    setColumnDefs(newColumnDefs);
    setColumnSetCount(nextColumnSetCount);
  };

  const mergeColumnsToOne = () => {
    const mergedCuts: Cut[] = [];

    cuts.forEach((cut) => {
      for (let i = 1; i <= columnSetCount; i++) {
        const idKey = `id_${i}`;
        const timestampKey = `timestamp_${i}`;
        const imgKey = `img_${i}`;
        const textKey = `text_${i}`;

        if (cut[idKey] !== null && cut[idKey] !== undefined) {
          mergedCuts.push({
            id: cut[idKey],
            img: cut[imgKey],
            timestamp:
              cut[timestampKey] !== undefined && cut[timestampKey] !== null
                ? cut[timestampKey]
                : 0,
            text: cut[textKey],
          });
        }
      }
    });

    const uniqueCuts = mergedCuts.filter(
      (cut, index, self) =>
        self.findIndex(
          (item) => item.id === cut.id && item.timestamp === cut.timestamp
        ) === index
    );

    const sortedCuts = uniqueCuts.sort((a, b) => (a.id || 0) - (b.id || 0));

    setCuts(sortedCuts); // 統合後の最新データを設定
    setColumnDefs(generateColumnDefs(headerNames)); // 初期カラム定義に戻す
    setColumnSetCount(1); // 列セット数をリセット
  };

  const handleTemporarySave = async () => {
    setIsProcessing(true); // ローディングを表示
    try {
      // S3 URLからディレクトリを抽出する関数
      const findDirectoryFromCuts = (): string | null => {
        for (const cut of cuts) {
          if (cut.img && cut.img.startsWith("https://")) {
            const directory = extractDirectoryFromS3Url(cut.img);
            if (directory) {
              return directory; // 最初に見つかった有効なディレクトリを返す
            }
          }
        }
        return null;
      };

      // 基準となるディレクトリを決定
      const baseDirectory = findDirectoryFromCuts() || "default/directory/";

      // Base64形式の画像をアップロードしてS3 URLに置き換え（imageQueueも含む）
      const updatedImageQueue = await Promise.all(
        imageQueue.map(async (img) => {
          if (img.imgUrl.startsWith("data:image")) {
            const s3Url = await uploadImageToS3(img.imgUrl, baseDirectory);
            return { ...img, imgUrl: s3Url }; // S3 URLに置き換え
          }
          return img;
        })
      );

      const updatedCuts = await Promise.all(
        cuts.map(async (cut) => {
          if (cut.img?.startsWith("data:image")) {
            const s3Url = await uploadImageToS3(cut.img, baseDirectory);
            return { ...cut, img: s3Url }; // S3 URLに置き換え
          }
          return cut; // 他の画像はそのまま返す
        })
      );

      // 保存データを収集
      const payload = {
        cuts: updatedCuts,
        imageQueue: updatedImageQueue, // imageQueueも保存対象に含める
        headerNames,
        layout: {
          columnSetCount,
          tableColor,
          baseImageHeight, // 現在の画像サイズ（高さ）を保存
        },
      };

      // サーバーに保存
      const url = `${BASE_URL}/api/v1/videos/${id}/temporary-save/`;
      const response = await apiRequestWithAuth(
        url,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        },
        () => {}
      );

      if (response) {
        console.log("Temporary save successful:", response);
        alert("一時保存が完了しました！");
        setIsEditing(false); // ユーザーが編集したことを追跡->保存で編集状態解除

        // 保存成功後、状態を更新
        setCuts(updatedCuts); // 最新のデータに更新
        setOriginalCuts(updatedCuts); // originalCutsも更新
        setImageQueue(updatedImageQueue); // 待機リストも更新
      }
    } catch (error) {
      console.error("Error during temporary save:", error);
      alert("一時保存中にエラーが発生しました。");
    } finally {
      setIsProcessing(false); // ローディングを非表示
    }
  };

  const extractDirectoryFromS3Url = (s3Url: string): string | null => {
    const match = s3Url.match(/^(.*\/results\/)/);
    return match ? match[1] : null;
  };

  const uploadImageToS3 = async (
    imageData: string,
    directory: string
  ): Promise<string> => {
    const url = `${BASE_URL}/api/v1/upload-to-s3/`;

    const response = await apiRequestWithAuth(
      url,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ image: imageData, directory }),
      },
      () => {}
    );

    if (response && response.s3_url) {
      return response.s3_url; // アップロード後のS3 URLを返す
    }

    throw new Error("Image upload failed");
  };

  // テキストのレンダリング幅を取得する関数
  const getRenderedTextWidth = (
    text: string,
    fontSize: number = 12,
    fontFamily: string = "Arial"
  ) => {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const textElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text"
    );
    textElement.setAttribute("font-size", fontSize.toString());
    textElement.setAttribute("font-family", fontFamily);
    textElement.textContent = text;
    svg.appendChild(textElement);
    document.body.appendChild(svg);
    const width = textElement.getBBox().width;
    document.body.removeChild(svg);
    return width;
  };

  const handleSaveAsPNG = async () => {
    try {
      const gridElement = document.querySelector(".ag-theme-alpine");

      if (!(gridElement instanceof HTMLElement)) {
        alert("AG Gridの要素が見つからないか、正しくありません");
        return;
      }

      // 選択された行を保存
      const selectedNodes = gridRef.current?.api.getSelectedNodes() || [];
      const selectedIds = selectedNodes.map((node) => node.id);

      // 選択を解除
      gridRef.current?.api.deselectAll();
      gridRef.current?.api.stopEditing(); // 編集を終了
      // 編集を確実に終了するために短時間待機
    await new Promise((resolve) => setTimeout(resolve, 50)); // 50ms待機

      // 現在のスタイルを保存
      const originalStyle = gridElement.style;
      
      // スクロールを解除して全体を表示
      gridElement.style.height = "auto";
      gridElement.style.overflow = "visible";

      // 「timestamp」列の再生アイコンを一時的に非表示にする
      const timestampCells = document.querySelectorAll(".timestamp-color");
      timestampCells.forEach((cell) => {
        const buttonElement = cell as HTMLElement;
        if (buttonElement) {
          buttonElement.dataset.originalHtml = buttonElement.innerHTML; // 元のHTMLを保存
          const timestampText = buttonElement.textContent?.trim() || ""; // タイムスタンプのみ取得
          buttonElement.innerHTML = `<span>${timestampText}</span>`; // タイムスタンプのみ表示
        }
      });

      // テーブル全体の高さを取得
      const scrollHeight = gridElement.scrollHeight;

      // キャンバスを生成
      const canvas = await html2canvas(gridElement, {
        scale: 2, // 高解像度
        height: scrollHeight, // 全体の高さをレンダリング
        useCORS: true, // クロスオリジン対応
        allowTaint: false, // 外部リソースの汚染を防ぐ
      });

      // 「timestamp」列を元の状態に戻す
      timestampCells.forEach((cell) => {
        const buttonElement = cell as HTMLElement;
        if (buttonElement && buttonElement.dataset.originalHtml) {
          buttonElement.innerHTML = buttonElement.dataset.originalHtml; // 元のHTMLに戻す
          delete buttonElement.dataset.originalHtml; // 一時的な属性を削除
        }
      });

      // 元のスタイルに戻す
      gridElement.style.height = originalStyle.height;
      gridElement.style.overflow = originalStyle.overflow;

      // PNG画像として保存
      const imgData = canvas.toDataURL("image/png", 1.0);
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "cuts_with_images.png";
      link.click();

      // 元の選択状態を復元
      selectedIds
        .filter((id): id is string => typeof id === "string") // undefinedを除外
        .forEach((id) => {
          const node = gridRef.current?.api.getRowNode(id);
          if (node) node.setSelected(true);
        });
    } catch (error) {
      console.error("PNG生成中にエラーが発生しました:", error);
      alert("PNG生成に失敗しました。");
    }
  };

  const handleSaveAsPDF = async () => {
    const gridElement = document.querySelector(".ag-theme-alpine");

    if (!(gridElement instanceof HTMLElement)) {
      alert("AG Gridの要素が見つからないか、正しくありません");
      return;
    }
    try {
      // 選択された行を保存
      const selectedNodes = gridRef.current?.api.getSelectedNodes() || [];
      const selectedIds = selectedNodes.map((node) => node.id);

      // 選択を解除
      gridRef.current?.api.deselectAll();
      gridRef.current?.api.stopEditing(); // 編集を終了
      // 編集を確実に終了するために短時間待機
      await new Promise((resolve) => setTimeout(resolve, 50)); // 50ms待機

      // 現在のスタイルを保存
      const originalStyle = gridElement.style;

      // スクロールを解除して全体を表示
      gridElement.style.height = "auto";
      gridElement.style.overflow = "visible";


      // AgGrid の幅を取得
      const gridWidthPx = gridElement.offsetWidth;

      // ピクセルを mm に変換 (DPI を 96 と仮定)
      const dpi = 300;
      const pxToMm = (px: number) => (px * 25.4) / dpi;

      // 「timestamp」列の再生アイコンを一時的に非表示にする
      const timestampCells = document.querySelectorAll(".timestamp-color");
      timestampCells.forEach((cell) => {
        const buttonElement = cell as HTMLElement;
        if (buttonElement) {
          buttonElement.dataset.originalHtml = buttonElement.innerHTML; // 元のHTMLを保存
          const timestampText = buttonElement.textContent?.trim() || ""; // タイムスタンプのみ取得
          buttonElement.innerHTML = `<span>${timestampText}</span>`; // タイムスタンプのみ表示
        }
      });

      // テーブル全体の高さを取得
      const scrollHeight = gridElement.scrollHeight;

      // キャンバスを生成
      const canvas = await html2canvas(gridElement, {
        scale: 2, // 高解像度
        height: scrollHeight, // 全体の高さをレンダリング
        useCORS: true, // クロスオリジン対応
        allowTaint: false, // 外部リソースの汚染を防ぐ
      });

      // 「timestamp」列を元の状態に戻す
      timestampCells.forEach((cell) => {
        const buttonElement = cell as HTMLElement;
        if (buttonElement && buttonElement.dataset.originalHtml) {
          buttonElement.innerHTML = buttonElement.dataset.originalHtml; // 元のHTMLに戻す
          delete buttonElement.dataset.originalHtml; // 一時的な属性を削除
        }
      });

      // 元のスタイルに戻す
      gridElement.style.height = originalStyle.height;
      gridElement.style.overflow = originalStyle.overflow;

      // キャンバスを画像に変換
      const imgData = canvas.toDataURL("image/png");

      // PDF ページサイズ（幅は固定、高さを動的に変更）
      const pdfWidth = pxToMm(gridWidthPx);
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // 行の高さを基にPDFの高さを計算
      const rowHeightPx = baseImageHeight || 100; // 行の高さ
      const totalRows = Math.ceil(imgHeight / rowHeightPx);
      const pdfHeight = (totalRows * rowHeightPx * pdfWidth) / imgWidth; // mm単位の高さを計算

      // PDF ドキュメントを生成
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [pdfWidth, pdfHeight], // 動的なページサイズを指定
      });

      // 縦横比を保ちながらPDF全体に画像を追加
      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // PDFを保存
      doc.save("cuts_with_images.pdf");

      // 元の選択状態を復元
      selectedIds
        .filter((id): id is string => typeof id === "string") // undefinedを除外
        .forEach((id) => {
          const node = gridRef.current?.api.getRowNode(id);
          if (node) node.setSelected(true);
        });
    } catch (error) {
      console.error("PDF生成中にエラーが発生しました:", error);
      alert("PDF生成に失敗しました。");
    }
  };

  const getRenderedImageSize = (
    rowIndex: number
  ): { width: number; height: number } | null => {
    // AgGrid 内の特定の行の画像要素を取得
    const imgElement = document.querySelector(
      `.ag-center-cols-container [row-index="${rowIndex}"] img`
    ) as HTMLElement | null;
    if (imgElement) {
      return {
        width: imgElement.offsetWidth, // 表示されている画像の幅 (px)
        height: imgElement.offsetHeight, // 表示されている画像の高さ (px)
      };
    }
    return null; // 画像が見つからない場合
  };

  const handleSaveAsExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Cuts");

      // カラム設定
      worksheet.columns = [
        { header: "ID", key: "id", width: 10 },
        { header: "Cut", key: "img", width: 30 },
        { header: "Timestamp", key: "timestamp", width: 15 },
        { header: "Text", key: "text", width: 30 },
      ];

      // AG Gridのデザイン設定
      const headerStyle = {
        font: { bold: true, color: { argb: "FF000000" } }, // 白文字
        alignment: { vertical: "middle", horizontal: "center" },
      };

      const defaultRowStyle = {
        font: { size: 11, color: { argb: "FF000000" } }, // 黒文字
        alignment: { vertical: "middle", horizontal: "center" },
      };

      const stripedRowStyle = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFEFEFEF" },
        }, // グレー
        alignment: { vertical: "middle", horizontal: "center" },
      };

      const blackRowStyle = {
        font: { size: 11, color: { argb: "FFFFFFFF" } },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF000000" },
        },
        alignment: { vertical: "middle", horizontal: "center" },
      };

      // ヘッダー行にデザインを適用
      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        Object.assign(cell, headerStyle);
      });

      // 画像データをBase64に変換する関数
      const fetchImageAsBase64 = async (url: string): Promise<string> => {
        const response = await fetch(url, { mode: "cors" });
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      };

      // データを行として追加
      for (const [index, cut] of cuts.entries()) {
        const timestamp =
          cut.timestamp !== null
            ? new Date(cut.timestamp * 1000).toISOString().substr(11, 12) // hh:mm:ss.***
            : "00:00:00";

        const row = worksheet.addRow({
          id: cut.id,
          timestamp,
          text: cut.text || "",
        });

        // 行デザインの適用
        let rowStyle = defaultRowStyle;

        switch (tableColor) {
          case "striped":
            rowStyle =
              index % 2 === 0
                ? { ...defaultRowStyle, ...stripedRowStyle }
                : defaultRowStyle;
            break;
          case "black":
            rowStyle = blackRowStyle;
            break;
          case "gray":
            rowStyle = { ...defaultRowStyle, ...stripedRowStyle };
            break;
          default:
            rowStyle = defaultRowStyle;
            break;
        }

        row.eachCell((cell) => Object.assign(cell, rowStyle));

        // 画像の埋め込み処理
        if (cut.img) {
          try {
            // AgGrid 上の画像サイズを取得
            const renderedSize = getRenderedImageSize(index);
            if (!renderedSize) {
              console.warn(`画像サイズを取得できませんでした: Row ${index}`);
              continue;
            }

            const { width: gridImageWidth, height: gridImageHeight } =
              renderedSize;

            // Base64 データ取得
            const base64Data = await fetchImageAsBase64(cut.img);
            const imageId = workbook.addImage({
              base64: base64Data.split(",")[1], // Base64データ部分のみ
              extension: "jpeg", // 必要に応じて png などに変更
            });

            // Excel に画像を埋め込む
            worksheet.addImage(imageId, {
              tl: { col: 1, row: index + 1 },
              ext: { width: gridImageWidth, height: gridImageHeight },
            });

            // 列幅を画像幅にぴったり合わせる（Excel の列幅はピクセル単位ではなく「文字幅」ベース）
            const excelColumnWidth = Math.ceil(gridImageWidth / 7.5); // ピクセルから文字幅に変換
            worksheet.getColumn(2).width = excelColumnWidth;

            // 行高さを画像高さに合わせる（ピクセル単位を高さに変換）
            const excelRowHeight = Math.ceil(gridImageHeight / 1.33); // ピクセルからポイントに変換
            worksheet.getRow(index + 2).height = excelRowHeight;
          } catch (error) {
            console.error(`画像の埋め込みに失敗しました (${cut.img}):`, error);
          }
        }
      }

      // 不要な余分な列を削除（もし自動で追加されていれば）
      worksheet.spliceColumns(5, worksheet.actualColumnCount - 4);

      // ファイルとして保存
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), "cuts_with_images.xlsx");
    } catch (error) {
      console.error("Excel保存中のエラー:", error);
      alert("Excelファイルの保存に失敗しました。");
    }
  };

  const handleSaveAsSVG = async () => {
    try {
      if (!gridRef.current) {
        alert("AG Gridの参照が見つかりませんでした");
        return;
      }

      const gridApi = gridRef.current.api;
      if (!gridApi) {
        alert("AG Grid APIが初期化されていません");
        return;
      }

      // ヘッダーとデータ行を取得
      const allColumns = gridApi.getColumnDefs();
      if (!allColumns || allColumns.length === 0) {
        alert("カラムが見つかりませんでした");
        return;
      }
      // 画像データをBase64に変換する関数
      const fetchImageAsBase64 = async (url: string): Promise<string> => {
        const response = await fetch(url, { mode: "cors" });
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      };

      const rowData: any[] = [];
      gridApi.forEachNode((node) => rowData.push(node.data)); // 全データ行を取得

      // SVG設定
      const rowHeight = baseImageHeight; // 各行の高さ
      const headerHeight = 40; // ヘッダーの高さ

      // 画像列の最大幅を計算
      /*
      const columnWidth = rowData.reduce((maxWidth, _, rowIndex) => {
        const renderedSize = getRenderedImageSize(rowIndex);
        return Math.max(maxWidth, renderedSize?.width || 300); // デフォルト幅を使用
      }, 300);*/

      // 列ごとに幅を計算
      const columnWidths = allColumns.map((columnDef: any) => {
        if (columnDef.field === "img") {
          // 画像列の場合、画像の最大幅を計算
          return rowData.reduce((maxWidth, _, rowIndex) => {
            const renderedSize = getRenderedImageSize(rowIndex);
            return Math.max(maxWidth, renderedSize?.width || 300); // デフォルト幅を使用
          }, 300);
        } else if (columnDef.field === "text") {
          // テキスト列の場合、テキストのレンダリング幅を計算
          return rowData.reduce((maxWidth, row) => {
            const cellValue = row[columnDef.field] || "";
            const wrappedLines = cellValue.toString().split("\n");
            const maxLineWidth = Math.max(
              ...wrappedLines.map((line: string) =>
                getRenderedTextWidth(line, 12, "Arial")
              )
            );
            return Math.max(maxWidth, maxLineWidth + 10); // 余白を追加
          }, 150); // デフォルト幅
        } else {
          // その他の列は固定幅
          return 150;
        }
      });

      //const svgWidth = allColumns.length * columnWidth;
      const svgWidth = columnWidths.reduce((total, width) => total + width, 0); // 全列の幅を合計
      const svgHeight = headerHeight + rowData.length * rowHeight;

      // SVGスタイル設定を動的に適用
      let rowStyle = ""; // デフォルトの行スタイルを設定

      switch (tableColor) {
        case "striped":
          rowStyle = `
      text { font-family: Arial, sans-serif; font-size: 12px; fill: #000; }
      rect { stroke: #ccc; stroke-width: 0.5; fill: #efefef; }
    `;
          break;
        case "black":
          rowStyle = `
      text { font-family: Arial, sans-serif; font-size: 12px; fill: #fff; }
      rect { stroke: #ccc; stroke-width: 0.5; fill: #000; }
    `;
          break;
        case "gray":
          rowStyle = `
      text { font-family: Arial, sans-serif; font-size: 12px; fill: #000; }
      rect { stroke: #ccc; stroke-width: 0.5; fill: #999; }
    `;
          break;
        default: // default or other cases
          rowStyle = `
      text { font-family: Arial, sans-serif; font-size: 12px; fill: #000; }
      rect { stroke: #ccc; stroke-width: 0.5; fill: #fff; }
    `;
          break;
      }

      let svgContent = `
  <svg xmlns="http://www.w3.org/2000/svg" width="${svgWidth}" height="${svgHeight}" viewBox="0 0 ${svgWidth} ${svgHeight}">
    <style>
      ${rowStyle}
    </style>
`;

      // ヘッダー行を追加
      /*
      allColumns.forEach((columnDef: any, colIndex: number) => {
        const headerText = columnDef.headerName || ""; // ヘッダー名を取得
        svgContent += `
        <rect x="${
          colIndex * columnWidth
        }" y="0" width="${columnWidth}" height="${headerHeight}" fill="#f2f2f2" />
        <text x="${colIndex * columnWidth + 5}" y="${
          headerHeight / 2
        }" alignment-baseline="middle">${headerText}</text>
      `;
      });*/
      // ヘッダー行を追加
      allColumns.forEach((columnDef: any, colIndex: number) => {
        const headerText = columnDef.headerName || "";
        const columnX = columnWidths
          .slice(0, colIndex)
          .reduce((total, width) => total + width, 0);
        svgContent += `
        <rect x="${columnX}" y="0" width="${
          columnWidths[colIndex]
        }" height="${headerHeight}" fill="#f2f2f2" />
        <text x="${columnX + 5}" y="${
          headerHeight / 2
        }" alignment-baseline="middle">${headerText}</text>
      `;
      });

      // データ行を追加
      const imagePromises: Promise<void>[] = [];

      rowData.forEach((row, rowIndex) => {
        allColumns.forEach((columnDef: any, colIndex: number) => {
          //const cellValue = row[columnDef.field];
          //const yPosition = headerHeight + rowIndex * rowHeight;

          /*svgContent += `
          <rect x="${
            colIndex * columnWidth
          }" y="${yPosition}" width="${columnWidth}" height="${rowHeight}" fill="white" />
        `;*/
          const cellValue = row[columnDef.field];
          const columnX = columnWidths
            .slice(0, colIndex)
            .reduce((total, width) => total + width, 0);
          const yPosition = headerHeight + rowIndex * rowHeight;

          svgContent += `
          <rect x="${columnX}" y="${yPosition}" width="${columnWidths[colIndex]}" height="${rowHeight}" fill="white" />
        `;

          if (columnDef.field === "img" && row.img) {
            // 画像がある場合
            imagePromises.push(
              (async () => {
                try {
                  // Base64 データ取得
                  const base64Data = await fetchImageAsBase64(row.img);

                  // 画像のサイズを取得
                  const imageSize = getRenderedImageSize(rowIndex) || {
                    //width: columnWidth,
                    width: columnWidths,
                    height: rowHeight,
                  };

                  /*svgContent += `
                  <image x="${colIndex * columnWidth}" y="${yPosition}" 
                         width="${imageSize.width}" height="${
                    imageSize.height
                  }" 
                         href="${base64Data}" />
                `;*/
                  svgContent += `
            <image x="${columnX}" y="${yPosition}" width="${columnWidths[colIndex]}" height="${rowHeight}" href="${base64Data}" />
          `;
                } catch (error) {
                  console.error(
                    `画像の埋め込みに失敗しました (${row.img}):`,
                    error
                  );
                }
              })()
            );
          } else {
            // テキストの場合
            if (columnDef.field === "text" && cellValue) {
              // 改行を考慮したテキスト描画
              const wrappedText = cellValue.toString().split("\n"); // \nで改行を分割

              // 各行を描画
              wrappedText.forEach((line: string, lineIndex: number) => {
                const lineYPosition = yPosition + 15 + lineIndex * 15; // 行間を調整
                /*svgContent += `
      <text x="${
        colIndex * columnWidth + 5
      }" y="${lineYPosition}" alignment-baseline="middle">
        ${line}
      </text>
    `;*/
                svgContent += `
              <text x="${
                columnX + 5
              }" y="${lineYPosition}" alignment-baseline="middle">${line}</text>
            `;
              });
            } else {
              const displayValue =
                columnDef.field === "timestamp"
                  ? formatTimestamp(cellValue || 0) // タイムスタンプをフォーマット
                  : cellValue !== null && cellValue !== undefined
                  ? cellValue
                  : ""; // 0 も描画するよう修正

              /*svgContent += `
            <text x="${colIndex * columnWidth + 5}" y="${
                yPosition + rowHeight / 2
              }" alignment-baseline="middle">
              ${displayValue}
            </text>
          `;*/
              svgContent += `
            <text x="${columnX + 5}" y="${
                yPosition + rowHeight / 2
              }" alignment-baseline="middle">${displayValue}</text>
          `;
            }
          }
        });
      });

      await Promise.all(imagePromises);

      svgContent += "</svg>";

      // SVGファイルとして保存
      const blob = new Blob([svgContent], {
        type: "image/svg+xml;charset=utf-8",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "aggrid_table.svg";
      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("SVG保存中のエラー:", error);
      alert("SVGファイルの保存に失敗しました。");
    }
  };

  const handleSaveAsFigma = async () => {
    try {
      if (!gridRef.current) {
        alert("AG Gridの参照が見つかりませんでした");
        return;
      }

      const gridApi = gridRef.current.api;
      if (!gridApi) {
        alert("AG Grid APIが初期化されていません");
        return;
      }

      const JSZip = (await import("jszip")).default;
      const zip = new JSZip();
      const imagesFolder = zip.folder("images"); // 画像を保存するフォルダ

      if (!imagesFolder) {
        throw new Error("画像フォルダの作成に失敗しました。");
      }

      // ヘッダーとデータ行を取得
      const allColumns = gridApi.getColumnDefs();
      if (!allColumns || allColumns.length === 0) {
        alert("カラムが見つかりませんでした");
        return;
      }

      const rowData: any[] = [];
      gridApi.forEachNode((node) => rowData.push(node.data));

      const rowHeight = baseImageHeight;
      const headerHeight = 40;

      // 最大画像幅を計算
      /*
      const columnWidth = rowData.reduce((maxWidth, _, rowIndex) => {
        const renderedSize = getRenderedImageSize(rowIndex);
        return Math.max(maxWidth, renderedSize?.width || 300);
      }, 300);*/
      // 列ごとに幅を計算
      const columnWidths = allColumns.map((columnDef: any) => {
        if (columnDef.field === "img") {
          return rowData.reduce((maxWidth, _, rowIndex) => {
            const renderedSize = getRenderedImageSize(rowIndex);
            return Math.max(maxWidth, renderedSize?.width || 300);
          }, 300);
        } else if (columnDef.field === "text") {
          return rowData.reduce((maxWidth, row) => {
            const cellValue = row[columnDef.field] || "";
            const wrappedLines = cellValue.toString().split("\n");
            const maxLineWidth = Math.max(
              ...wrappedLines.map((line: string) =>
                getRenderedTextWidth(line, 12, "Arial")
              )
            );
            return Math.max(maxWidth, maxLineWidth + 10);
          }, 150);
        } else {
          return 150;
        }
      });

      //const svgWidth = allColumns.length * columnWidth;
      const svgWidth = columnWidths.reduce((total, width) => total + width, 0);
      const svgHeight = headerHeight + rowData.length * rowHeight;

      // SVGスタイル設定を動的に適用
      let rowStyle = "";
      switch (tableColor) {
        case "striped":
          rowStyle = `
          text { font-family: Arial, sans-serif; font-size: 12px; fill: #000; }
          rect { stroke: #ccc; stroke-width: 0.5; fill: #efefef; }
        `;
          break;
        case "black":
          rowStyle = `
          text { font-family: Arial, sans-serif; font-size: 12px; fill: #fff; }
          rect { stroke: #ccc; stroke-width: 0.5; fill: #000; }
        `;
          break;
        case "gray":
          rowStyle = `
          text { font-family: Arial, sans-serif; font-size: 12px; fill: #000; }
          rect { stroke: #ccc; stroke-width: 0.5; fill: #999; }
        `;
          break;
        default:
          rowStyle = `
          text { font-family: Arial, sans-serif; font-size: 12px; fill: #000; }
          rect { stroke: #ccc; stroke-width: 0.5; fill: #fff; }
        `;
          break;
      }

      let svgContent = `
      <svg xmlns="http://www.w3.org/2000/svg" width="${svgWidth}" height="${svgHeight}" viewBox="0 0 ${svgWidth} ${svgHeight}">
        <style>
          ${rowStyle}
        </style>
    `;

      // ヘッダー行
      /*
      allColumns.forEach((columnDef: any, colIndex: number) => {
        const headerText = columnDef.headerName || "";
        svgContent += `
        <rect x="${
          colIndex * columnWidth
        }" y="0" width="${columnWidth}" height="${headerHeight}" fill="#f2f2f2" />
        <text x="${colIndex * columnWidth + 5}" y="${
          headerHeight / 2
        }" alignment-baseline="middle">${headerText}</text>
      `;
      });*/

// 列データが ColDef かどうかを判定する型ガード
const isColDef = (column: ColDef<any, any> | ColGroupDef<any>): column is ColDef<any, any> => {
  return "field" in column;
};

      // ヘッダー行
      allColumns.forEach((columnDef: any, colIndex: number) => {
        if (!isColDef(columnDef)) {
          // field がない列（ColGroupDef）はスキップ
          return;
        }
        const headerText = columnDef.headerName || "";
        const columnX = columnWidths
          .slice(0, colIndex)
          .reduce((total, width) => total + width, 0);
        svgContent += `
        <rect x="${columnX}" y="0" width="${
          columnWidths[colIndex]
        }" height="${headerHeight}" fill="#f2f2f2" />
        <text x="${columnX + 5}" y="${
          headerHeight / 2
        }" alignment-baseline="middle">${headerText}</text>
      `;
      });

      const fetchImageAsBase64 = async (url: string): Promise<string> => {
        const response = await fetch(url, { mode: "cors" });
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      };

      // データ行と画像の非同期処理
      /*const rowPromises = rowData.map(async (row, rowIndex) => {
        await Promise.all(
          allColumns.map(async (columnDef: any, colIndex: number) => {*/
      for (const [rowIndex, row] of rowData.entries()) {
        for (const [colIndex, columnDef] of allColumns.entries()) {
          //const cellValue = row[columnDef.field];
          //const yPosition = headerHeight + rowIndex * rowHeight;

          /*svgContent += `
        <rect x="${
          colIndex * columnWidth
        }" y="${yPosition}" width="${columnWidth}" height="${rowHeight}" fill="white" />
      `;*/
      // 型ガードを使用して ColDef のみを処理
      if (!isColDef(columnDef)) return;
          //const cellValue = row[columnDef.field];
          const cellValue = columnDef.field ? row[columnDef.field] : null;
          const columnX = columnWidths
            .slice(0, colIndex)
            .reduce((total, width) => total + width, 0);
          const yPosition = headerHeight + rowIndex * rowHeight;
          svgContent += `
          <rect x="${columnX}" y="${yPosition}" width="${columnWidths[colIndex]}" height="${rowHeight}" fill="white" />
        `;

          if (columnDef.field === "img" && row.img) {
            const imageUrl = row.img;
            const imageName =
              imageUrl.split("/").pop() || `image_${rowIndex}_${colIndex}.jpg`; // ファイル名が取得できない場合のデフォルト名
            const relativePath = `images/${imageName}`;

            // 非同期で画像を取得しZipに追加
            try {
              // データ URI に変換
              /*const response = await fetch(imageUrl, { mode: "cors" });
                const blob = await response.blob();
                const reader = new FileReader();*/
              const base64Data = await fetchImageAsBase64(imageUrl);
              imagesFolder.file(imageName, base64Data.split(",")[1], {
                base64: true,
              });

              const renderedSize = getRenderedImageSize(rowIndex) || {
                //width: columnWidth,
                width: columnWidths[colIndex],
                height: rowHeight,
              };
              /*reader.onloadend = () => {
                  const dataUri = reader.result as string;
                  imagesFolder.file(imageName, blob); // Zipに画像を追加*/
              /*svgContent += `
              <image x="${colIndex * columnWidth}" y="${yPosition}" 
                     width="${renderedSize.width}" height="${
                    renderedSize.height
                  }" 
                     href="${dataUri}" />
            `;*/
              svgContent += `
              <image x="${columnX}" y="${yPosition}" 
                     width="${renderedSize.width}" height="${renderedSize.height}" 
                     href="${base64Data}" />
            `;
              //};
              //reader.readAsDataURL(blob);
            } catch (error) {
              console.error(
                `画像の取得または追加に失敗しました (${imageUrl}):`,
                error
              );
            }
          } else if (columnDef.field === "text" && cellValue) {
            const wrappedLines = cellValue.toString().split("\n");
            wrappedLines.forEach((line: string, lineIndex: number) => {
              const lineYPosition = yPosition + 15 + lineIndex * 15;
              svgContent += `
                  <text x="${
                    columnX + 5
                  }" y="${lineYPosition}" alignment-baseline="middle">${line}</text>
                `;
            });
          } else {
            const displayValue =
              columnDef.field === "timestamp"
                ? formatTimestamp(cellValue || 0)
                : cellValue || "";

            /*svgContent += `
          <text x="${colIndex * columnWidth + 5}" y="${
                yPosition + rowHeight / 2
              }" alignment-baseline="middle">
            ${displayValue}
          </text>
        `;*/
            svgContent += `
            <text x="${columnX + 5}" y="${
              yPosition + rowHeight / 2
            }" alignment-baseline="middle">${displayValue}</text>
          `;
          }
        } //)
        //);
        //});
      }

      //await Promise.all(rowPromises); // すべての行の処理が完了するまで待機

      svgContent += "</svg>";

      // SVGをZipに追加
      zip.file("table.svg", svgContent);

      // Zipファイルを生成
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const zipUrl = URL.createObjectURL(zipBlob);

      // ダウンロードリンクを生成してクリック
      const link = document.createElement("a");
      link.href = zipUrl;
      link.download = "table_with_images.zip";
      link.click();

      URL.revokeObjectURL(zipUrl);
    } catch (error) {
      console.error("SVGとZip保存中のエラー:", error);
      alert("SVGとZipファイルの保存に失敗しました。");
    }
  };

  const handleSaveAsDataZip = async () => {
    try {
      if (!gridRef.current) {
        alert("AG Gridの参照が見つかりませんでした");
        return;
      }

      const gridApi = gridRef.current.api;
      if (!gridApi) {
        alert("AG Grid APIが初期化されていません");
        return;
      }

      const JSZip = (await import("jszip")).default;
      const zip = new JSZip();
      const imagesFolder = zip.folder("images");
      if (!imagesFolder) throw new Error("画像フォルダの作成に失敗しました");

      const rowData: any[] = [];
      gridApi.forEachNode((node) => rowData.push(node.data));

      // 各行の非同期処理を収集
      const processedRows = await Promise.all(
        rowData.map(async (row, rowIndex) => {
          const id = row["id"] || rowIndex;
          const timestamp =
            row["timestamp"] !== undefined
              ? new Date(row["timestamp"] * 1000).toISOString().substr(11, 12)
              : "00:00:00";
          const text = row["text"] || "";
          const imageUrl = row["img"] || "";
          let imageName = "";

          if (imageUrl) {
            try {
              // 画像の取得と保存
              const response = await fetch(imageUrl, { mode: "cors" });
              const blob = await response.blob();
              imageName = imageUrl.split("/").pop() || `image_${rowIndex}.jpg`;
              imagesFolder.file(imageName, blob);
            } catch (error) {
              console.error(
                `画像の取得または追加に失敗しました (${imageUrl}):`,
                error
              );
            }
          }

          return { id, imageName, timestamp, text };
        })
      );

      // ID順にソート
      processedRows.sort((a, b) => a.id - b.id);

      // CSV用のデータ構築
      /*let csvContent = "ID,Image Filename,Timestamp,Text\n";
      processedRows.forEach(({ id, imageName, timestamp, text }) => {
        csvContent += `${id},${imageName},${timestamp},${text}\n`;
      });*/
      // CSV用のデータ構築
let csvContent = "ID,Image Filename,Timestamp,Text\n";
processedRows.forEach(({ id, imageName, timestamp, text }) => {
  // テキストセルに改行やカンマが含まれている場合の処理
  const sanitizedText = `"${text.replace(/"/g, '""')}"`; // ダブルクオートで囲み、内部のダブルクオートをエスケープ
  csvContent += `${id},${imageName},${timestamp},${sanitizedText}\n`;
});

      // CSVファイルを追加
      zip.file("data.csv", csvContent);

      // ZIPファイルを生成
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const zipUrl = URL.createObjectURL(zipBlob);

      // ダウンロードリンクを生成してクリック
      const link = document.createElement("a");
      link.href = zipUrl;
      link.download = "table_data.zip";
      link.click();

      URL.revokeObjectURL(zipUrl);
    } catch (error) {
      console.error("データZIP保存中のエラー:", error);
      alert("データZIPファイルの保存に失敗しました。");
    }
  };

  const handleSave = async (format: string) => {
    try {
      setIsProcessing(true); // 保存処理開始時にインジケーターを表示
      if (format === "png") {
        await handleSaveAsPNG();
      } else if (format === "pdf") {
        await handleSaveAsPDF();
      } else if (format === "xlsx") {
        await handleSaveAsExcel();
      } else if (format === "svg") {
        await handleSaveAsSVG();
      } else if (format === "figma") {
        await handleSaveAsFigma();
      } else if (format === "row") {
        await handleSaveAsDataZip();
      }
    } catch (error) {
      console.error("保存処理中のエラー:", error);
      alert("保存に失敗しました。");
    } finally {
      setIsProcessing(false); // 保存処理終了後にインジケーターを非表示
    }
  };

  // レスポンシブ対応UI
  return (
    <Box>
      {/* 保存処理中のインジケーター */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isProcessing} // 保存処理中のみ表示
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* カット表のtable */}
      <Box
        className="ag-theme-alpine"
        sx={{
          height: "calc(100vh - 150px)", // ユーティリティバー＋展開バーの高さを考慮
          overflowX: "auto", // 横スクロールを可能に
          overflowY: "auto", // 表全体をスクロール可能に
          mb: 4, // マージンボトムで余裕を追加
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={cuts}
          columnDefs={columnDefs}
          getRowHeight={getRowHeight}
          components={{
            customHeader: (props: IHeaderParams) => (
              <CustomHeader {...props} updateHeaderName={updateHeaderName} />
            ),
          }}
          domLayout="autoHeight"
          defaultColDef={{
            sortable: false,
            resizable: true,
            editable: true,
            flex: 1,
          }}
          rowSelection="single"
          rowClassRules={rowClassRules}
          onGridReady={(params) => {
            params.api.sizeColumnsToFit();
          }}
        />
      </Box>

      {/* 展開バー */}
      {activePanel && (
        <Box
          sx={{
            position: "fixed",
            bottom: "62px",
            left: 0,
            right: 0,
            backgroundColor: "#f8f9fa",
            boxShadow: "0px -2px 5px rgba(0,0,0,0.1)",
            zIndex: 1100,
            px: 2,
            py: 1,
            overflowX: "auto", // 横スクロールを有効化
            display: "flex",
            gap: 2,
            alignItems: "center",
            flexWrap: { xs: "wrap", sm: "nowrap" }, // 小さい画面では折り返し
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
        >
          {activePanel === "rowOperations" && (
            <>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleAddRowAt("above")}
                sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
              >
                選択行の上に追加
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleAddRowAt("below")}
                sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
              >
                選択行の下に追加
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleAddRow}
                sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
              >
                最終行を追加
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleDeleteRow}
                sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
              >
                選択行を削除
              </Button>
            </>
          )}

          {activePanel === "saveOperations" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column", // 垂直方向に配置
                  alignItems: { xs: "center", sm: "flex-start" }, // モバイルは中央揃え、PCは左揃え
                  gap: 2, // 注釈とボタン群の間隔
                  mb: 2, // 下部余白
                }}
              >
                {/* 保存時の注釈 */}
                <Typography
                  variant="body2"
                  sx={{
                    color: "red",
                    mb: 2,
                    textAlign: { xs: "center", sm: "left" }, // モバイルで中央揃え
                    fontSize: { xs: "0.8rem", sm: "1rem" }, // サイズ調整
                  }}
                >
                  ※
                  保存結果に画像が含まれない場合、ブラウザのキャッシュをクリアし、ページを更新してください。
                </Typography>
                {/* ボタン群 */}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap", // モバイルで折り返し可能に
                    gap: 2, // ボタン間のスペース
                    justifyContent: { xs: "center", sm: "flex-start" }, // モバイルは中央揃え、PCは左揃え
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleSave("png")}
                    disabled={!permissions.allows_png_download || isMobile}
                    sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                  >
                    png
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleSave("pdf")}
                    disabled={!permissions.allows_pdf_download || isMobile}
                    sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                  >
                    PDF
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleSave("xlsx")}
                    disabled={!permissions.allows_excel_download}
                    sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                  >
                    Excel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleSave("svg")}
                    disabled={!permissions.allows_svg_download}
                    sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                  >
                    SVG
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleSave("figma")}
                    disabled={!permissions.allows_figma_download}
                    sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                  >
                    Figma用zip
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleSave("row")}
                    disabled={!permissions.allows_row_download}
                    sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                  >
                    rowデータ
                  </Button>
                </Box>
              </Box>
            </>
          )}

          {activePanel === "tableDesign" && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "column", // テキストとセレクトボックスを横並び
                  alignItems: "center",
                  gap: 3, // 文字とセレクトボックスの間に余白を追加
                }}
              >
                <InputLabel sx={{ pb: 2 }}>テーブルの色</InputLabel>
                <Select
                  value={tableColor}
                  onChange={handleTableColorChange}
                  sx={{ minWidth: 120 }}
                  size="small"
                >
                  <MenuItem value="default">背景白</MenuItem>
                  <MenuItem value="black">背景黒 (文字白)</MenuItem>
                  <MenuItem value="gray">背景グレー (文字黒)</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">画像サイズ変更:</Typography>
                <Slider
                  value={baseImageHeight}
                  min={50}
                  max={300}
                  step={10}
                  onChange={(e, value) => adjustImageHeight(value as number)}
                  valueLabelDisplay="auto"
                  sx={{ width: 200 }}
                />
              </Box>
            </Box>
          )}

          {activePanel === "imageCutting" && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                justifyContent: { xs: "center", sm: "flex-start" }, // スマホは中央寄せ、PCは左寄せ
                mt: 2,
              }}
            >
              <video
                ref={videoRef}
                src={videoUrl}
                controls
                muted
                crossOrigin="anonymous"
                style={{ width: "100%", maxWidth: "300px" }}
              />
              <Button
                variant="contained"
                startIcon={<Camera />}
                onClick={handleCutScene}
                sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
              >
                切り出し
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  overflowX: "auto",
                  maxHeight: { xs: "150px", sm: "none" }, // スマホ時の高さ制限
                  mt: 2,
                  paddingBottom: 1, // スクロール時の見やすさ向上
                  width: { xs: "100%", sm: "100%" },
                  justifyContent: { xs: "center", sm: "flex-start" }, // スマホは左寄せ、PCは中央寄せ
                }}
              >
                {imageQueue.map((img, index) => (
                  <Box
                    key={img.imgUrl}
                    sx={{
                      border:
                        selectedImage === img.imgUrl
                          ? "2px solid blue"
                          : "1px solid #ccc",
                      borderRadius: 1,
                      p: 1,
                      cursor: "pointer",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column", // 縦方向に配置
                      alignItems: "center", // 中央揃え
                      gap: 1, // 画像とボタンの間にスペースを追加
                      minWidth: "50px", // 最小幅を指定
                    }}
                    onClick={() => setSelectedImage(img.imgUrl)}
                  >
                    <img
                      src={img.imgUrl}
                      alt={`waiting-${index}`}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "80px",
                        objectFit: "cover",
                      }}
                    />
                    {selectedImage === img.imgUrl && (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          addImageToTable(img.imgUrl, img.timestamp)
                        }
                        sx={{ mt: 1, fontSize: { xs: "0.8rem", sm: "1rem" } }} // ボタンの上に余白を追加
                      >
                        カット表に追加
                      </Button>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      )}

      {/* ユーティリティバー */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#fff",
          boxShadow: "0px -2px 5px rgba(0,0,0,0.1)",
          zIndex: 1000,
          px: 2,
          py: 1,
          overflowX: "auto", // 横スクロールを有効化
          display: "flex",
          gap: 2,
          justifyContent: { xs: "center", sm: "flex-start" },
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            setActivePanel(
              activePanel === "rowOperations" ? null : "rowOperations"
            )
          }
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem" }, // スマホは小さめ、PCは少し大きめ
          }}
        >
          行編集
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            setActivePanel(activePanel === "tableDesign" ? null : "tableDesign")
          }
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem" }, // スマホは小さめ、PCは少し大きめ
          }}
        >
          表デザイン
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            setActivePanel(
              activePanel === "imageCutting" ? null : "imageCutting"
            )
          }
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem" }, // スマホは小さめ、PCは少し大きめ
          }}
        >
          画像追加
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            setActivePanel(
              activePanel === "saveOperations" ? null : "saveOperations"
            )
          }
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem" }, // スマホは小さめ、PCは少し大きめ
          }}
        >
          書き出し
        </Button>
        {/* 一時保存ボタンを追加 */}
        <Button
          variant="contained"
          size="small"
          onClick={handleTemporarySave}
          sx={{
            fontSize: { xs: "0.8rem", sm: "1rem" }, // スマホは小さめ、PCは少し大きめ
          }}
        >
          一時保存
        </Button>
      </Box>
    </Box>
  );
};

export default CutTable;
