import React from "react";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import { useNavigate } from 'react-router-dom';

interface MenuProps {
    isDrawerOpen: boolean;
    onClose: () => void;
}

const Menu: React.FC<MenuProps> = ({ isDrawerOpen, onClose}) => {
    const navigate = useNavigate();

    return (
        <>
        {/* スマホ向けのDrawer */}
      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={onClose}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1, // Drawerのz-indexを強調
          display: { xs: "block", sm: "none" }, // スマホのみ表示
          '& .MuiDrawer-paper': {
                        marginTop: '64px', // ヘッダーの高さを考慮（デフォルトAppBar高さ）
                    },
        }}
      >
            <List>
                <ListItem button onClick={() => navigate('/dashboard')}>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button onClick={() => navigate('/upload')}>
                    <ListItemText primary="Upload" />
                </ListItem>
                 {/*<ListItem button onClick={() => navigate('/settings')}>
                    {/*<ListItemText primary="Settings" />
                </ListItem>*/}
            </List>
        </Drawer>

        <List sx={{ display:{ xs:'none', sm:'block'} }}> {/* PC時の表示 */}
            <ListItem button onClick={() => navigate('/dashboard')}>
                <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button onClick={() => navigate('/upload')}>
                <ListItemText primary="Upload" />
            </ListItem>
            {/*<ListItem button onClick={() => navigate('/settings')}>
                <ListItemText primary="Settings" />
            </ListItem>*/}
        </List>
        </>
    );
};



export default Menu;