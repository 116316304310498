import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserPlanProvider } from "./components//UserPlanContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Menu from "./components/Menu";
import Home from "./components/Home";
import Dashboard from './components/Dashboard';
import CutTable from "./components/CutTable";
import CutTableWrapper from "./components/CutTableWrapper";
import Upload from './components/Upload';
import Settings from './components/Settings';
import Login from "./components/Login";
import Register from "./components/Register";
import PasswordReset from "./components/PasswordReset";
import PasswordResetConfirm from "./components/PasswordResetConfirm"; 
import SelectLoginMethod from "./components/SelectLoginMethod";
import Subscription from "./components/Subscription";
import { Box, Avatar, Button, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google';

import { apiRequestWithAuth, refreshToken, handleLogout } from "./utils/auth";
import { handleLogin, handleRegister, handleGoogleLogin, handleGoogleRegister } from "./utils/user";



const theme = createTheme({
  palette: {
    primary: {
      main: '#0D47A1', //ネイビーブルー
    },
    secondary: {
      main: '#ff7043', //コーラルオレンジ
    },
    background:{
      default: '#F5F5F5', //ライトグレー
      paper: '#FFFFFF', //ホワイト
    },
    text:{
      primary: '#424242', //チャコールグレー
      secondary: '#757575', //ライトグレー
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serfi',
    h6: {
      fontWeight: 600,
      letterSpacing: '0.05em',
    },
    button: {
      textTransform: 'none', //ボタンのテキストを通常のケースで表示
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: "auto", // 横スクロールを有効化
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowX: "hidden", // グローバルに横スクロールの発生を防ぐ
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px', //ボタンに丸みをもたせる
        },
      },
    },
  },
});

const App: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); //メニューの開閉状態を管理するフラグ
  const [IsLoggedIn, setIsLoggedIn] = useState(false); //ログイン状態を管理するフラグ
  const [userName, setUserName] = useState("User Name"); //ユーザー名を管理
  const [userPlan, setUserPlan] = useState("Free"); //契約プランタイプを管理
  const [videoUploadCount ,setVideoUploadCount] = useState(0); //動画アップ可能数
  const [ispdfDownload, setIspdfDownload] = useState(true);
  const [isExcelDownload, setIsExcelDownload] = useState(false);
  const [isSvgDownload, setIsSvgDownload] = useState(false);
  const [ispngDownload, setIspngDownload] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [isSelectingMethod, setIsSelectingMethod] = useState(false); //選択画面の表示管理
  const [errorMessage, setErrorMessage] = useState("");
  const BASE_URL = (process.env.REACT_APP_API_BASE_URL || '').trim();

  const navigate = useNavigate();
  const location = useLocation();

  const isPaidUser = userPlan !== "Free"; // Free プラン以外を課金と判定


  // 状態リセット関数
  const resetState = () => {
    setIsLoggedIn(false);
    setUserName("");
  };

  const initializeUserState = async () => {
    const publicPaths = [
      '/login',
      '/register',
      '/select-login',
      '/select-register',
      '/password-reset',
      '/password-reset-confirm'
    ];
    const isPublicPath = publicPaths.some(path => location.pathname.startsWith(path));
  
    if (!isPublicPath) {
      console.log("initializeUserState called");
      const refreshSucceeded = await refreshToken(navigate);
  
      if (refreshSucceeded) {
        console.log("Token refreshed successfully. User session is active.");
        setIsLoggedIn(true);
      } else {
        console.warn("Token refresh failed. Logging out.");
        setIsLoggedIn(false);
        handleLogout(navigate, resetState);
      }
    }
  };

// 初期化時にトークンを確認（画面読み込みやページ遷移で発火）
useEffect(() => {
  const initialize = async () => {
    await initializeUserState();
  };
  initialize();
}, [location.pathname, navigate]);


  const toggleDrawer = (open: boolean) => () => {
    setIsDrawerOpen(open);
  };


  const handleFormSelect = () => {
    setIsSelectingMethod(false);
    setShowLogin(true);
  }

  const handleRegisterFormSelect = () => {
    setIsSelectingMethod(false);
    setShowRegister(true);
  };

  const fetchUserInfo = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/user-info/`, {
        method: 'GET',
        credentials: 'include', // クッキーをリクエストに含める
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        // プラン情報やアップロード回数を基にUIを調整
        setUserName(data.username);
        setUserPlan(data.plan);
        setVideoUploadCount(data.video_upload_count_today);
        setIspdfDownload(data.allows_pdf_download);
        setIsExcelDownload(data.allows_excel_download);
        setIsSvgDownload(data.allows_illustrator_download);
        setIspngDownload(data.allows_png_download);
        setIsLoggedIn(true); // ユーザー情報が取得できたらログイン状態をtrueにする
      } else {
        console.warn('Failed to fetch user info:', response.status);
      }
    } catch (error) {
      console.error('An error occurred while fetching user info:', error);
    }
  };
  

  const CutTableWrapper: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    
    const videoUrl = location.state?.videoUrl || ""; // 動的に渡された videoUrl
    const isPaidUser = userPlan !== "Free"; // App.tsx の userPlan を参照
  
    return <CutTable videoUrl={videoUrl} isPaidUser={isPaidUser} />;
  };
  
    
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || ""; // 環境変数から読み込む
  if (!googleClientId) {
    console.error("Google Client ID is not defined. Please check your .env file.");
  }

  return (
    <UserPlanProvider isPaidUser={isPaidUser}>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Box
        display="flex"
        flexDirection="column"
        width="100%" // 幅を全画面に設定
        maxWidth="100%" // 横幅の制限を解除
        minHeight="100vh" // 高さを画面全体に設定
        sx={{
          overflowX: "hidden", // 横方向のオーバーフローを防ぐ
        }}
      >
        <Header 
          onMenuClick={toggleDrawer(true)}
          isLoggedIn={IsLoggedIn}
          userName={userName}
          onLoginClick={() => { setIsSelectingMethod(true); setShowRegister(false); setShowLogin(true);  }}
          onRegisterClick={() => { setIsSelectingMethod(true); setShowLogin(false); setShowRegister(true); }}
          handleLogout={() => handleLogout(navigate, resetState)}  // navigateを引数に渡す
        />
        <Box display="flex" flexGrow={1}>
          {IsLoggedIn && (
            <Menu isDrawerOpen={isDrawerOpen} onClose={toggleDrawer(false)} />
          )}
          <Box
              component="main"
              p={3}
              flexGrow={1}
              sx={{
                overflowX: "auto", // 横スクロールを許可
                width: "100%",
                marginTop: isDrawerOpen ? "240px" : "0", // Drawer幅に応じて調整
                transition: "margin-top 0.3s ease", // なめらかなトランジションを追加
              }}
            >
            <GoogleOAuthProvider clientId={googleClientId}>
              {/* 1つの Routes コンポーネントにまとめる */}
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/upload" element={<Upload />} />
                {/*<Route path="/settings" element={<Settings />} />*/}
                <Route path="/select-login" element={
                  <SelectLoginMethod 
                  onFormSelect={() => {}}
                  onGoogleSelect={(token) => handleGoogleLogin(token, setIsLoggedIn, setUserName, setShowLogin, fetchUserInfo)}
                  isRegister={false}
                />
                }/>
                <Route path="/select-register" element={
                  <SelectLoginMethod 
                  onFormSelect={() => {}}
                  onGoogleSelect={(token) => handleGoogleRegister(token, setIsLoggedIn, setUserName, setShowLogin, fetchUserInfo)}
                  isRegister={true}
                />
                }/>
                <Route path="/login" element={<Login onLogin={(email, password) => handleLogin(email, password, setIsLoggedIn, setUserName, setShowLogin, setErrorMessage)} onGoogleLogin={async (token) => {await handleGoogleLogin(token, setIsLoggedIn, setUserName, setShowLogin, fetchUserInfo);}} />} />
                <Route path="/register" element={<Register 
                                                          onRegister={(email, password) => handleRegister(email, password, setIsLoggedIn, setUserName, setShowRegister, setErrorMessage)} 
                                                          onGoogleRegister={async (token) => {
                                                            // handleGoogleRegister を呼び出して非同期対応
                                                            await handleGoogleRegister(token, setIsLoggedIn, setUserName, setShowLogin, fetchUserInfo);
                                                          }} 
                                                        />} />
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route path="/password-reset-confirm/:uid/:token" element={<PasswordResetConfirm />} />
                <Route path="/Subscription" element={<Subscription />} />
                <Route path="/cut-table/:id" element={<CutTableWrapper />} />
                <Route path="/" element={
                  IsLoggedIn ? (
                    <Home /> // ログイン時は説明ページを表示
                  ) : (
                    <Typography variant="h4">ログインしてください！！！</Typography>
                  )
                }/>
              </Routes>
            </GoogleOAuthProvider>
          </Box>
        </Box>
        <Footer />
    
    </Box>
  </ThemeProvider>
  </UserPlanProvider>
  );
};

export default App;