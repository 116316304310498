import React, { useState } from "react";

import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { apiRequestWithAuth } from "../utils/auth";

const Upload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const BASE_URL = (process.env.REACT_APP_API_BASE_URL || "").trim();

  const navigate = useNavigate();

  const handleFileChange = (file: File) => {
    if (file && file.type === "video/mp4") {
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreview(fileUrl);
    } else {
      alert("MP4ファイルのみアップロード可能です");
    }
  };

  // react-dropzone の設定
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "video/mp4": [] },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      handleFileChange(file);
    },
    multiple: false,
  });

  const handleUpload = async () => {
    if (!selectedFile) return;
    setIsUploading(true);

    const formData = new FormData();
    formData.append("video", selectedFile);

    const response = await apiRequestWithAuth(
      `${BASE_URL}/api/v1/videos/upload/`,
      {
        method: "POST",
        body: formData, // フォームデータをボディとして送信
      },
      navigate
    );

    setIsUploading(false);

    if (response?.error) {
      if (response.error === "アップロード制限に達しました") {
        console.log(response);
        // 制限超過時
        alert(`${response.error} (${response.time_until_reset})`);
      } else {
        // その他のエラー
        alert("アップロードに失敗しました。時間をおいて再度お試しください。");
      }
    } else if (response) {
      // 成功時
      alert(
        "アップロードが完了しました。カット表が出来上がるまでお待ち下さい。"
      );
      navigate("/dashboard");
    }
  };

  return (
    <Card sx={{ maxWidth: 600, mx: "auto", mt: 4, boxShadow: 3 }}>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h5" gutterBottom>
            動画をアップロード
          </Typography>

          {/* ドラッグアンドドロップエリア */}
          <Box
            {...getRootProps()}
            sx={{
              border: "2px dashed #ccc",
              borderRadius: "8px",
              padding: "20px",
              width: "100%", // 親要素に対する横幅を統一
              maxWidth: "500px", // 横幅の最大値を統一
              textAlign: "center",
              mb: 2,
              backgroundColor: isDragActive ? "#f0f8ff" : "#fafafa",
              cursor: "pointer",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // シャドウ
              mx: "auto", // 中央寄せ
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography variant="body2" color="primary">
                ファイルをここにドロップしてください...
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                ここにファイルをドラッグ＆ドロップするか、クリックして選択
              </Typography>
            )}
          </Box>

          {/* 注釈 */}
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              mb: 2,
              backgroundColor: "#f9f9f9", // 背景色
              p: 2, // パディング
              borderRadius: "8px", // 角丸
              border: "2px solid #ddd", // 枠線スタイル
              textAlign: "center", // テキスト中央揃え
              fontSize: { xs: "0.8rem", sm: "1rem" }, // フォントサイズ
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // シャドウ
              width: "100%", // 親要素に対する横幅を統一
              maxWidth: "500px", // 横幅の最大値を統一
              mx: "auto", // 中央寄せ
            }}
          >
            <strong>重要なお知らせ:</strong>{" "}
            ファイル名は半角英数字のみ対応しています。記号や空白を含む場合、自動的に変更されます。
            <br />
            無音または音声が含まれない動画は、カット表が正しく作成されない可能性がありますのでご注意ください。
          </Typography>

          {/* プレビュー */}
          {preview && (
            <Box sx={{ width: "100%", maxWidth: 500, mb: 2 }}>
              <video
                muted
                src={preview}
                controls
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          )}
          {/* アップロードボタン */}
          <Button
            variant="contained"
            onClick={handleUpload}
            disabled={!selectedFile || isUploading}
            sx={{ width: "100%", maxWidth: 300 }}
          >
            {isUploading ? <CircularProgress size={24} /> : "アップロード"}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Upload;
