import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  IconButton,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material"; // MUIから必要なコンポーネントをインポート
import { Visibility, VisibilityOff, CheckCircle } from "@mui/icons-material"; // アイコン用のインポート
import GoogleAuthButton from "./GoogleAuthButton";

interface RegisterFormProps {
  onRegister: (email: string, password: string) => Promise<boolean>;
  onGoogleRegister: (token: string) => Promise<void>; // 非同期関数に変更
}

const Register: React.FC<RegisterFormProps> = ({
  onRegister,
  onGoogleRegister,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | string[] | null>(
    null
  );
  const [isRegister, setIsRegister] = useState(false); // ログイン処理中の状態
  const [showPassword, setShowPassword] = useState(false); // パスワード表示切り替え
  const [isPasswordValid, setIsPasswordValid] = useState(false); // パスワード条件を満たしたか
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false); // プライバシーポリシーと利用規約の同意状態

  // 汎用エラーハンドリング関数
  const handleError = (error: any) => {
    if (error?.response?.status === 401) {
      setErrorMessage("メールアドレスまたはパスワードが間違っています");
    } else if (error?.response?.status === 400) {
      setErrorMessage("入力内容を確認してください");
    } else if (error?.message === "Network Error") {
      setErrorMessage(
        "ネットワークエラーが発生しました。インターネット接続を確認してください。"
      );
    } else {
      setErrorMessage(
        "予期しないエラーが発生しました。時間をおいて再試行してください。"
      );
    }
  };

  // メールアドレスのバリデーション関数
  const isValidEmail = (email: string) => {
    // メールアドレスの基本構文を検証
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

    // 使用禁止の文字（絵文字や特殊文字）
    const forbiddenCharsRegex = /[^\x20-\x7E]/; // ASCII範囲外の文字を検出

    // 絵文字や特殊文字が含まれているか確認
    if (forbiddenCharsRegex.test(email)) {
      return false; // 絵文字や特殊文字が検出された場合は無効
    }

    // メールアドレスのフォーマット検証
    return emailRegex.test(email);
  };

  // パスワード条件チェック
  const validatePassword = (password: string) => {
    const lengthValid = password.length >= 8;
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasLetter = /[a-zA-Z]/.test(password);
    return lengthValid && hasNumber && hasSymbol && hasLetter;
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setPassword(password); // 既存のonChange処理
    setIsPasswordValid(validatePassword(password)); // パスワードのバリデーション処理
  };

  const navigate = useNavigate();

  // メールアドレスとパスワードによる新規登録処理
  const handleRegister = async () => {
    if (!email || !password) {
      setErrorMessage("メールアドレスとパスワードを入力してください");
      return;
    }

    if (email && password) {
      setIsRegister(true); // ログイン処理開始
      setErrorMessage(null); // エラーメッセージをリセット
      try {
        const isSuccess = await onRegister(email, password); // 新規登録処理
        if (isSuccess) {
          navigate("/"); // 登録成功時にのみ遷移
        } else {
          handleError({ response: { status: 401 } }); // ログイン失敗を401エラーとして扱う
        }
      } catch (error) {
        console.error("Registration failed:", error);
        setErrorMessage(["新規登録に失敗しました"]);
      } finally {
        setIsRegister(false); // ログイン処理終了
      }
    } else {
      setErrorMessage(["メールアドレスとパスワードを入力してください"]);
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 4 }}>
      {/* ログイン中のインジケーター */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isRegister}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        component="form"
        autoComplete="on" // フォーム全体の自動補完を有効化
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" gutterBottom>
          新規登録
        </Typography>

        {/* エラーメッセージ表示 */}
        {errorMessage && (
          <Alert
            severity="error"
            sx={{ mb: 2 }}
            onClose={() => setErrorMessage(null)}
          >
            {errorMessage}
          </Alert>
        )}

        <TextField
          label="メールアドレス"
          variant="outlined"
          margin="normal"
          autoComplete="username" // メールアドレス用
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            width: "100%", // 幅を100%に設定
            maxWidth: 450,
            margin: "0 auto", // 左右中央寄せ
          }}
        />
        <TextField
          label="パスワード"
          variant="outlined"
          margin="normal"
          type={showPassword ? "text" : "password"} // パスワード表示切り替え
          autoComplete="new-password" // 自動補完用の属性を追加
          value={password}
          onChange={handlePasswordChange}
          sx={{
            width: "100%", // 幅を100%に設定
            maxWidth: 450,
            margin: "5 auto 0", // 左右中央寄せ
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                {isPasswordValid && (
                  <CheckCircle sx={{ color: "green", ml: 1 }} />
                )}{" "}
                {/* パスワードが有効ならチェック表示 */}
              </InputAdornment>
            ),
          }}
        />
        <Typography
          gutterBottom
          sx={{ width: "100%", maxWidth: 450, margin: "5 auto 0" }}
        >
          <ul>
            <li style={{ color: password.length >= 8 ? "green" : "red" }}>
              8文字以上である
            </li>
            <li style={{ color: /\d/.test(password) ? "green" : "red" }}>
              数字が含まれる
            </li>
            <li
              style={{
                color: /[!@#$%^&*(),.?":{}|<>]/.test(password)
                  ? "green"
                  : "red",
              }}
            >
              記号が含まれる
            </li>
            <li style={{ color: /[a-zA-Z]/.test(password) ? "green" : "red" }}>
              英字が含まれる
            </li>
          </ul>
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={isPolicyAccepted}
              onChange={(e) => setIsPolicyAccepted(e.target.checked)}
              color="primary"
            />
          }
          label={
            <Typography variant="body2">
              登録することで、
              <a href="https://tokimoa.jp/information/privacy/" target="_blank" rel="noopener noreferrer">
                プライバシーポリシー
              </a>
              と
              <a href="https://tokimoa.jp/terms_of_service/" target="_blank" rel="noopener noreferrer">
                利用規約
              </a>
              に同意したものとみなされます。
            </Typography>
          }
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleRegister}
          sx={{ marginTop: 2 }}
          disabled={!isValidEmail(email) || !isPasswordValid || !isPolicyAccepted}
        >
          登録
        </Button>
        <Typography variant="h6" sx={{ marginTop: 3, marginBottom: 3 }}>
          または
        </Typography>
        <GoogleAuthButton onLoginSuccess={onGoogleRegister} isRegister={true} />
      </Box>
    </Container>
  );
};

export default Register;
